import { type CSSProperties, type ReactElement, cloneElement } from 'react';

import type { ElementProps } from '../types.js';
import { iconStyles } from './icon.css.js';

export interface IconProps extends ElementProps<'svg'> {
  /**
   * A screen reader only label for the Icon.
   */
  'aria-label'?: string;
  /**
   * The content to display. Should be an SVG.
   */
  children: ReactElement<any>;
  /**
   * Size of Icon (changes based on scale).
   */
  size?: 'XXS' | 'XS' | 'S' | 'M' | 'L' | 'XL' | 'XXL';
  /**
   * A slot to place the icon in.
   * @default 'icon'
   */
  slot?: string;
  /**
   * Indicates whether the element is exposed to an accessibility API.
   */
  'aria-hidden'?: boolean | 'false' | 'true';

  style?: CSSProperties;
}

export function Icon(props: IconProps) {
  const {
    children,
    'aria-label': ariaLabel,
    'aria-hidden': ariaHidden,
    ...restProps
  } = props;

  return cloneElement(children, {
    ...restProps,
    focusable: 'false',
    'aria-label': ariaLabel,
    'aria-hidden': ariaLabel ? ariaHidden || undefined : true,
    role: 'img',
    className: iconStyles,
  });
}
